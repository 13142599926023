'use strict';

import StringUtils from './StringUtils';
import ConfigUtils from './ConfigUtils';

const jsonFiles: any = {
    'FR': require('./i18n/messages_fr.json'),
    'EN': require('./i18n/messages_en.json'),
    'DE': require('./i18n/messages_de.json')
};

const simpleMessageFiles : any = {
    "FR": require("./i18n/FR.json"),
    "EN": require("./i18n/EN.json"),
    "DE": require("./i18n/DE.json")
};

interface I18NMessage {
    title: string;
    body: string;
    expert: string;
}

class I18NUtils {
    //Singleton definition
    private messages: { [key: string]: { [key: string]: I18NMessage}};
    private static _instance: I18NUtils;
    private simpleMessages: {[key: string]: { [key: string]: string }};

    constructor() {

        //initialisation of messages in all set languages
        for (const lang in jsonFiles) {
            if (this.messages === undefined) {
                this.messages = {};
            }

            if (this.messages[lang] === undefined) {
                this.messages[lang] = {};
            }

            const currentLangMessages: any = jsonFiles[lang];
            for (const messageKey in currentLangMessages) {
                this.messages[lang][messageKey] = currentLangMessages[messageKey];
            }
        }

        for (const lang in simpleMessageFiles) {
            if (this.simpleMessages === undefined) {
                this.simpleMessages = {};
            }

            if (this.simpleMessages[lang] === undefined) {
                this.simpleMessages[lang] = {};
            }

            const currentLangMessages: any = simpleMessageFiles[lang];
            for (const messageKey in currentLangMessages) {
                this.simpleMessages[lang][messageKey] = currentLangMessages[messageKey];
            }
        }
    }

    private populateMessagesFromFile(sourceFiles, target):void {
        for (const lang in sourceFiles) {
            if (target === undefined) {
                target = {};
            }

            if (target[lang] === undefined) {
                target[lang] = {};
            }

            const currentLangMessages: any = jsonFiles[lang];
            for (const messageKey in currentLangMessages) {
                target[lang][messageKey] = currentLangMessages[messageKey];
            }
        }
    }

    static get Instance() {
        return this._instance || (this._instance = new this());
    }

    /**
     * Retrieves the user messages from the application json properties files.
     * The french language is the default one. If the message does not exist,
     * then it'll be looked up in french. If it still doesn't exist, then
     * a default error message will be fetched. If no properties are defined,
     * then an hardcoded message will be set.
     *
     * @param messageId The identifier of the message to fetch
     * @param lang The language in which we want the message
     */
    public getMessage(messageId: string, lang: string, context: { [key: string]: any }): I18NMessage {

        const localContext = Object.assign({}, context);
        if (messageId !== 'MISSING_MESSAGE') {
            localContext['messageId'] = messageId;
        }

        //If there are no messages at all
        if (this.messages === undefined) {
            return {
                title: 'Fichiers properties manquants',
                body: 'Les fichiers internationalisés de messages ne sont pas présents. Contactez votre administrateur.',
                expert: 'Les fichiers json ne sont pas chargés'
            };
        }

        //If the requested language is not set
        if (lang === undefined || lang.trim() === '') {
            lang = 'FR';
        }

        //If the requested language messages are not defined we fallback to the
        //default language
        if (this.messages[lang] === undefined) {
            lang = 'FR';
        }

        let foundMessage = this.messages[lang][messageId];
        if (foundMessage === undefined && this.messages['FR'] !== undefined && lang !== 'FR') {
            //Message doesn't exist we try it in french if the requested language
            //isn't french already
            foundMessage = this.getMessage(messageId, 'FR', localContext);
        }

        if (foundMessage === undefined && messageId != 'MISSING_MESSAGE') {
            //The message still cannot be found. Falling back to the standard
            //missing message
            foundMessage = this.getMessage('MISSING_MESSAGE', lang, localContext);
        }

        if (foundMessage === undefined) {
            //We still can't find the requested message at all. We fallback to
            //an hardcoded one
            return {
                title: 'Le message ' + messageId + ' est introuvable',
                body: 'Des messages internationalisés sont introuvables. contactez votre administrateur.',
                expert: 'Le message est introuvable ainsi que le fallback MISSING_MESSAGE'
            };
        }

        //We might have to inject variables into the message
        const injectedMessage = Object.assign({}, foundMessage);
        injectedMessage.title = StringUtils.resolveTextProperties(injectedMessage.title, localContext);
        injectedMessage.body = StringUtils.resolveTextProperties(injectedMessage.body, localContext);
        injectedMessage.expert = StringUtils.resolveTextProperties(injectedMessage.expert, localContext);
        return injectedMessage;
    }

    public getText(textId: string, lang: string, context: { [key:string] : any}): string {

        const localContext = Object.assign({}, context);
        if (textId !== 'MISSING_TEXT') {
            localContext['textId'] = textId;
        }

        //If there are no text at all
        if (this.simpleMessages === undefined) {
            return 'Fichiers properties manquants';
        }

        //If the requested language is not set
        if (lang === undefined || lang.trim() === '') {
            lang = 'FR';
        }

        //If the requested language text are not defined we fallback to the
        //default language
        if (this.simpleMessages[lang] === undefined) {
            lang = 'FR';
        }

        let foundText: string = this.simpleMessages[lang][textId];
        if (foundText === undefined && this.simpleMessages['FR'] !== undefined && lang !== 'FR') {
            //Text doesn't exist we try it in french if the requested language
            //isn't french already
            foundText = this.getText(textId, 'FR', localContext);
        }

        if (foundText === undefined && textId != 'MISSING_TEXT') {
            //The text still cannot be found. Falling back to the standard
            //missing text
            foundText = this.getText('MISSING_TEXT', lang, localContext);
        }

        if (foundText === undefined) {
            //We still can't find the requested text at all. We fallback to
            //an hardcoded one
            'Le text ' + textId + ' est introuvable';
        }

        //We might have to inject variables into the text
        return StringUtils.resolveTextProperties(foundText, localContext);
    }

    public getTextIfExists(textId: string, lang: string, context: { [key:string] : any}): string {
        const localContext = Object.assign({}, context);
        //If the requested language is not set
        if (lang === undefined || lang.trim() === '') {
            lang = 'FR';
        }

        //If the requested language text are not defined we fallback to the
        //default language
        if (this.simpleMessages[lang] === undefined) {
            lang = 'FR';
        }

        let foundText: string = this.simpleMessages[lang][textId];
        if (foundText === undefined && this.simpleMessages['FR'] !== undefined && lang !== 'FR') {
            //Text doesn't exist we try it in french if the requested language
            //isn't french already
            foundText = this.getText(textId, 'FR', localContext);
        }
        return foundText;
    }

    // Get the internationalization option for the Materialize DatePicker
    public getDatePickerIntl(lang: string): {[key:string]: any} {
        return {
            cancel: this.getText('cancel', lang, {}),
            clear: this.getText('clear', lang, {}),
            done: this.getText('done', lang, {}),
            months: [this.getText('jan', lang, {}),
                this.getText('feb', lang, {}),
                this.getText('mar', lang, {}),
                this.getText('apr', lang, {}),
                this.getText('may', lang, {}),
                this.getText('jun', lang, {}),
                this.getText('jul', lang, {}),
                this.getText('aug', lang, {}),
                this.getText('sep', lang, {}),
                this.getText('oct', lang, {}),
                this.getText('nov', lang, {}),
                this.getText('dec', lang, {}),],
            monthsShort: [this.getText('jan_short', lang, {}),
                this.getText('feb_short', lang, {}),
                this.getText('mar_short', lang, {}),
                this.getText('apr_short', lang, {}),
                this.getText('may_short', lang, {}),
                this.getText('jun_short', lang, {}),
                this.getText('jul_short', lang, {}),
                this.getText('aug_short', lang, {}),
                this.getText('sep_short', lang, {}),
                this.getText('oct_short', lang, {}),
                this.getText('nov_short', lang, {}),
                this.getText('dec_short', lang, {}),],
            weekdays: [this.getText('sun', lang, {}),
                this.getText('mon', lang, {}),
                this.getText('tue', lang, {}),
                this.getText('wed', lang, {}),
                this.getText('thu', lang, {}),
                this.getText('fri', lang, {}),
                this.getText('sat', lang, {})],
            weekdaysShort: [this.getText('sun_short', lang, {}),
                this.getText('mon_short', lang, {}),
                this.getText('tue_short', lang, {}),
                this.getText('wed_short', lang, {}),
                this.getText('thu_short', lang, {}),
                this.getText('fri_short', lang, {}),
                this.getText('sat_short', lang, {})],
            weekdaysAbbrev: [this.getText('sun_abr', lang, {}),
                this.getText('mon_abr', lang, {}),
                this.getText('tue_abr', lang, {}),
                this.getText('wed_abr', lang, {}),
                this.getText('thu_abr', lang, {}),
                this.getText('fri_abr', lang, {}),
                this.getText('sat_abr', lang, {})]
        };
    }
}

const singleInstance = I18NUtils.Instance;

export { I18NMessage };
export default singleInstance;

