import { Server, Authentication } from '@sai/protocol';
import I18NUtils from './utils/I18NUtils';
import ConfigUtils from './utils/ConfigUtils';
import RUtils from './utils/RecordUtils';
import RecordUtils from './utils/RecordUtils';
import ErrorUtils from './utils/ErrorUtils';

let lang = $('html').attr('lang').toUpperCase() || 'FR';

Server.initialize({
    serverUrl: location.origin + ConfigUtils.getRelativePath(),
    deviceType: 'web',
    callbacks: {
        sessionTimeoutCallback: undefined,
        globalFailureCallback: (error: any) => {},
        missingAuthCallback: (message: any, resolve: any, reject: any) => {reject('error')},
        ongoingMessageCallback: undefined,
        displayMessageCallback: undefined,
        i18NEntryCallback: (code: string) => I18NUtils.getMessage(code, lang, {})
    },
    timeout: 0
});

let loginBtn = $('#login-btn');
loginBtn.on('click', (event)  => {
    event.preventDefault();
    $('.login-error').hide();
    let logF = $('#login-email');
    let passF = $('#login-password');
    let redirect = $('#login-redirect').val();
    $('.missing-input, .invalid-credentials').hide();
    RecordUtils.startLoading(loginBtn);
    if(RUtils.validateField(logF) && RUtils.validateField(passF)) {
        const loginMessage = new Authentication.LoginRequest(logF.val(), passF.val(), null);
        Server.performRequest(loginMessage)
            .then(() => {
                sessionStorage['usermail'] = logF.val();
                if (redirect && (redirect as string).indexOf('/') == 0) {
                    window.location.href = redirect as string;
                } else {
                    window.location.href = '/home';
                }
            })
            .catch((error) => {
                $('.login-error').html(ErrorUtils.getErrorDescription(error, lang));
                $('.login-error').show();
                RecordUtils.stopLoading(loginBtn);
            })
    } else {
        $('.missing-input').show();
        RecordUtils.stopLoading(loginBtn);
    }
});

$('input').on('keypress', function(event) {
    if(event.which == 13) {
        loginBtn.trigger('click', event);
    }
});

$(".feather").on('click', function (event) {
    event.preventDefault();
    let target = $('#login-password'); 
    if (target.attr("type") == "text") {
        target.attr('type', 'password');
        target.attr('placeholder', '........');
    } else if (target.attr("type") == "password") {
        target.attr('type', 'text');
        target.attr('placeholder', I18NUtils.getText('userlogin_label_secret', lang, {}));
    }
});