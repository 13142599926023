const moment = require('moment');

class StringUtils {
    static resolveTextProperties(label: string, contextToUse: { [key:string] : any}): string {
        let context = contextToUse;
        if (context === undefined) {
            context = {};
        }

        if (!label) {
            return label;
        }

        const regex = /\$\{(.+?)\}/g;
        const matches = [];
        let match = regex.exec(label);
        while (match !== null) {
            matches.push(match[1]);
            match = regex.exec(label);
        }
        let toReplace = label;
        for (const strKey in matches) {
            const toMatch = '\$\{' + matches[strKey] + '\}';
            if (context === undefined || context[matches[strKey]] === undefined) {
                toReplace = toReplace.replace(toMatch, '');
            } else {
                toReplace = toReplace.replace(toMatch, context[matches[strKey]]);
            }
        }
        return toReplace;
    }

    static underscoreToCamelCase(label: string): string {
        //show_select_grid will match _s and _g
        const regex = /(_.)/g;
        const matches = [];
        let match = regex.exec(label);
        while (match !== null) {
            matches.push(match[1]);
            match = regex.exec(label);
        }
        let toReplace = label;
        for (const strKey in matches) {
            //replacing _s by S
            toReplace = toReplace.replace(matches[strKey], matches[strKey].substring(1, 2).toUpperCase());
        }
        //show_select_grid becomes showSelectGrid
        return toReplace;
    }

    static basename(someString: string): string {
        if (someString === undefined) {
            return someString;
        } else {
            let base = someString.substring(someString.lastIndexOf('/') + 1);
            if (base.lastIndexOf('.') != -1) {
                base = base.substring(0, base.lastIndexOf('.'));
            }
            return base;
        }
    }

    static guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    static formatValue(value: string, datafield: any, combo: any, alreadyFormatted: boolean): string {
        if (value === undefined || value === '' || datafield === undefined) {
            return value;
        }

        const format = datafield.getFormat();
        let formattedValue;
        const nbDecimal = datafield.getDecimalsNumber() !== undefined ? datafield.getDecimalsNumber() : 2;
        let parsedValue;
        switch (format) {
            case 'Integer':
                formattedValue = alreadyFormatted ? value : parseInt(value).toString();
                break;
            case 'Financial':
                formattedValue = alreadyFormatted ? value : parseFloat(value).toFixed(nbDecimal).toString();
                break;
            case 'Float':
                formattedValue = alreadyFormatted ? value : parseFloat(value).toFixed(nbDecimal).toString();
                break;
            case 'Year':
                formattedValue = alreadyFormatted ? value : moment(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
                break;
            case 'Date':
                parsedValue = moment(value, 'YYYY-MM-DD HH:mm:ss', true);
                formattedValue = parsedValue !== undefined && parsedValue.isValid() ? parsedValue.format('L') : value;
                break;
            case 'DateTime':
                parsedValue = moment(value, 'YYYY-MM-DD HH:mm:ss', true);
                formattedValue = parsedValue !== undefined && parsedValue.isValid() ? parsedValue.format('L HH:mm') : value;
                break;
            case 'Time':
                parsedValue = moment(value, 'YYYY-MM-DD HH:mm:ss', true);
                formattedValue = parsedValue !== undefined && parsedValue.isValid() ? parsedValue.format('HH:mm') : value;
                break;
            case 'Boolean':
                formattedValue = value;
                break;
            case 'Enum':
            case 'Select':
                formattedValue = this.getEnumDisplay(value, datafield, combo);
                break;
            case 'Percentage':
                const fValue = parseFloat(value) * 100;
                formattedValue = fValue.toFixed(2) + '%';
                break;
            default:
                formattedValue = value;
        }
        return formattedValue;
    }

    static getEnumDisplay(enumKey: string, datafield: any, combo: any) {
        if (!combo) {
            console.warn('Combo linked to datafield ' + datafield.getId() + ' not found, using key \'' + enumKey + '\' instead');
            return enumKey;
        }
        const datafieldConfig = datafield.getConfig;
        const displayCode = datafieldConfig['displayCode'];
        if (displayCode === 'CodeOnly') {
            return enumKey;
        } else if (displayCode === 'TextOnly') {
            return combo.getElement(enumKey) ? combo.getElement(enumKey).getLabel() : enumKey;
        } else {
            // Default: code + text
            let result = enumKey;
            if (combo.getElement(enumKey)) {
                result += ' - ' + combo.getElement(enumKey).getLabel();
            }
            return result;
        }
    }
}

export default StringUtils;