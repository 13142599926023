'use strict';
import { identity } from 'lodash';
import {v4 as uuidv4} from 'uuid';
import I18NUtils, {I18NMessage} from "./I18NUtils";

interface SAINetError {
    code: string;
    body: string;
    status: string;
}

class ErrorUtils {
    //Singleton definition
    private static _instance: ErrorUtils;
    private callbacks:Array<Function>;
    
    private constructor() {
        this.callbacks = [];
        this.addButtonEventListener();
    }

    static get Instance() {
        return this._instance || (this._instance = new this());
    }

    getRelativePath() {
        return ErrorUtils['relativePath'] !== undefined ? ErrorUtils['relativePath'] : '/';
    }

    displayError(errorMessage: string) {
        $('#error-message').find('.error-details').empty();
        $('#error-message').find('.error-details').append($.parseHTML(errorMessage));
        $('#error-message').show();
    }

    hideError() {
        $('#error-message').hide();
        this.performCallback();
    }

    displaySAINETError(errorMessage:string) {
        let messageDiv = $('#sainet-message').find('.message-body');
        messageDiv.empty();
        messageDiv.append($.parseHTML(errorMessage.split('[N]').join('<BR>')));
        $('#sainet-message').show();
    }

    hideSAINETError() {
        $('#sainet-message').hide();
        this.performCallback();
    }

    public addCallback(f:Function) {
        this.callbacks.push(f);
    }

    private performCallback() {
        let callbacksCopy:Array<Function> = this.callbacks.map(identity); // Make a copy of the callbacks
        this.callbacks = []; // Clear the callback array
        callbacksCopy.forEach(c => c()); // Perform the callbacks
    }

    createAlert($el: JQuery<HTMLElement>, errorTitle: string, errorMessage: string, additionalClass?: string): void {
        let uuid = uuidv4();
        let modalAlert = '<div class="modal modal-fullscreen-m" tabindex="-1" role="dialog" id="'+uuid+'">'
        + '<div class="modal-dialog" role="document">'
        + '    <div class="modal-content">'
        + '        <div class="modal-header">'
        + '            <h5 class="modal-title">'
        + errorTitle
        + '            </h5>'
        + '            <button type="button" class="close" data-dismiss="modal" aria-label="Close">'
        + '                <span aria-hidden="true">&times;</span>'
        + '            </button>'
        + '        </div>'
        + '        <div class="modal-body">'
        + errorMessage
        + '        </div>'
        + '    </div>'
        + '</div>'
        + '</div>'
        $el.append(modalAlert);
        if(additionalClass) {
            $('#'+uuid).find('.modal-content').addClass(additionalClass);
        }
        $('#'+uuid).on('hidden.bs.modal', function () {
            $(this).remove(); // remove the modal from the dom
        });
        ($('#'+uuid) as any).modal();
    }

    public getErrorDescription(errorStr: string, lang?: string): string {
        let error:any;
        try {
            error = JSON.parse(errorStr);
        } catch(e) {
            error = errorStr;
        }
        let errorCode:string = undefined;
        if(this.isI18NMessage(error)) {
            errorCode = error.title;
        } else if(this.isErrorMessage(error)) {
            errorCode = error.name;
        } else if(this.isSAINetErrorMessage(error)) {
            errorCode = error.code;
        } else if(Object.prototype.hasOwnProperty.call(error, "message")) {
            // error from a fetch call
            errorCode = error.name;
        } else if(typeof error === "string"){
            errorCode = error;
        }
        // Check if there is a translation for the code
        let errorDescription:string = undefined;
        if(errorCode && errorCode.length > 0) {
            errorDescription = I18NUtils.getTextIfExists(errorCode, lang, {});
        }

        if(!errorDescription || errorDescription.length <= 0) {
            // No translation found for this code or no code
            // Check the content of the error
            if(this.isI18NMessage(error)) {
                errorDescription = error.body;
            } else if(this.isErrorMessage(error)) {
                errorDescription = error.message;
            } else if(this.isSAINetErrorMessage(error)) {
                errorDescription = error.body;
            } else if(Object.prototype.hasOwnProperty.call(error, "message")) {
                // error from a fetch call
                errorDescription = error.message;
            } else if(Object.prototype.hasOwnProperty.call(error, "responseJSON")) {
                errorDescription = error.responseJSON.message;
            } else if(typeof error === "string"){
                errorDescription = error;
            } else {
                errorDescription = JSON.stringify(error);
            }
        }
        return errorDescription.replace('[N]', '<BR/>');
    }

    private isI18NMessage(message: any): message is I18NMessage {
        return Object.prototype.hasOwnProperty.call(message, "title") && Object.prototype.hasOwnProperty.call(message, "body");
    }

    private isErrorMessage(message: any): message is Error {
        return Object.prototype.hasOwnProperty.call(message, "name") && Object.prototype.hasOwnProperty.call(message, "message");
    }

    private isSAINetErrorMessage(message: any): message is SAINetError {
        return Object.prototype.hasOwnProperty.call(message, "code") && Object.prototype.hasOwnProperty.call(message, "body");
    }

    private addButtonEventListener():void {
        $('#error-message').find('button').on('click', () => {
            this.hideError();
        });
    
        $('#error-message').find('.show-details').on('click', () => {
            const details = $('#error-message').find('.error-details');
            if(details.is(':visible')) {
                details.hide();
            } else {
                details.show();
            }
        });
    
        $('#sainet-message').find('button').on('click', () => {
            this.hideSAINETError();
        });
    }

    public jsonParse(error:string):any {
        try {
            return JSON.parse(error);
        } catch(e) {
            return undefined;
        }
    }
}

const singleInstance = ErrorUtils.Instance;
export default singleInstance;