'use strict';

import Config from '../Config';
const { Server, Task, DomainContext, ContextsCollection } = require("../../../../node_modules/@sai/protocol/dist/sai-protocol-node");
const { CClientConfiguration, PageInstance} = require("../../../../node_modules/@sai/configuration/dist/sai-configuration-node");

class ConfigUtils {
    private cache: { [key: string]: any }
    //Singleton definition
    private static _instance: ConfigUtils;

    private constructor() {
        this.cache = {};
    }

    static get Instance() {
        return this._instance || (this._instance = new this());
    }

    getRelativePath() {
        return Config['relativePath'] !== undefined ? Config['relativePath'] : '/';
    }

    getConfigurationValue(feature: string, configurationItem: string): Promise<any> {
        const cacheId = feature + "." + configurationItem;
        if(!this.cache[cacheId]) {
            const configPromise = new Promise((accept, reject) => {
                this.getFeatureConfiguration(feature)
                .then((configPage) => {
                    const fields = configPage.getFields();
                    const fieldID = "UNI91."+feature+"."+configurationItem;
                    if(fields[fieldID]) {
                        accept(fields[fieldID]);
                    } else {
                        reject("No such configuration item");
                    }
                }).catch(reject);
            });
            this.cache[cacheId] = configPromise;
        }
        return this.cache[cacheId];
    }

    getFeatureConfiguration(feature: string): Promise<any> {
        const cacheId = feature + "_config";
        if(!this.cache[cacheId]) {
            const portalDomain = process.env.SAINET_DOMAIN;
            const configPromise = new Promise((accept, reject) => {
                Server.getContext(portalDomain)
                .then((domain: typeof DomainContext) => {
                    // Building dossier request
                    const contexts = new ContextsCollection();
                    const dossierContext = contexts.getOrCreate("key", true);
                    dossierContext.addFields([{ datafieldId: "UNIPOR.ID_PK", value: portalDomain }]);
                    const dossierRequest = new Task.GetDossierRequest(domain, "UNI91");
                    dossierRequest.setKeyContext(dossierContext);
                    
                    //Retrieval of dossier and associated parametrage
                    Promise.all([Server.performRequest(dossierRequest), CClientConfiguration.getTask("UNI91", domain)])
                        .then(([dossier, uni91]) => {
                            let featurePage = undefined;
                            for (const idx in dossier.task.pages) {
                                const pageConfig = dossier.task.pages[idx];
                                //Retrieving subscription page from dossier if exists
                                const instance = new PageInstance(pageConfig, uni91, null, domain);
                                if (instance.getId() === feature) {
                                    featurePage = instance;
                                }
                            }
                            if(featurePage) {
                                accept(featurePage);
                            } else {
                                reject("Unable to find configuration page for " + feature + " in domain " + portalDomain);
                            }
                        }).catch(reject);
                }).catch(reject);
            });
            this.cache[cacheId] = configPromise;
        }
        return this.cache[cacheId];
    }
}

const singleInstance = ConfigUtils.Instance;
export default singleInstance;

