class RecordUtils {
    /**
     * Build a average 12th ratio based on a given 100th value
     * @param value The string value to scale
     */
    static getGridPosFromValue(value: string): number {
        if(value === undefined || value === '' || value.indexOf('%') < 0) {
            return 12; //No value or no % thus we return the max
        } else {
            value = value.replace('%', '');
            let intValue = parseInt(value);
            intValue = Math.floor(intValue * 12 / 100);
            return intValue;
        }
    }

    static validateField(field): boolean {
        field.removeClass('invalid');
        field.removeClass('valid');

        var fieldValue = field.val();
        if (fieldValue === '') {
            field.addClass('invalid');
        } else {
            field.addClass('valid');
        }
        return fieldValue !== '';
    }

    static startLoading(field): void {
        field.prop('disabled', true);
        field.children('.text').hide();
        field.children('.load').show();
    }

    static stopLoading(field): void {
        field.prop('disabled', false);
        field.children('.load').hide();
        field.children('.text').show();

    }
}

export default RecordUtils;